@media print {
    @page {
        size: landscape;
        margin: 3mm 10mm;
        size: 1123px 794px;
    }
    .legend {
        /* break-after: always;
        page-break-after: always; */
        min-height: 100px;
    }
}
