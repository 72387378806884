@import "./theme.scss";

@mixin reset-appearance {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font: inherit;
    list-style: none;
    appearance: none;
    border: none;
    outline: none;
    background: transparent;
    color: inherit;
    line-height: inherit;
    text-align: inherit;
}

@mixin box-shadow {
    box-shadow: 0 $spacing * 0.25 $spacing * 0.25 0 transparentize($color-paper-inverse, 0.9);
}
