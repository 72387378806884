@import "../theme.scss";

.CustomLoading {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.Inline {
    display: inline-flex;
}

.SVG {
    max-height: 100%;
    max-width: 100%;
    flex: 0 1 auto;
}
