.sidebar {
    animation-name: slidein;
    animation-duration: 500ms;
}

@keyframes slidein {
    from {
        translate: -5vw 0;
    }
    to {
        translate: 0 0;
    }
}