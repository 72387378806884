@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-2xl my-5;
    }
    h2 {
        @apply text-xl my-5;
    }
    h3 {
        @apply text-xl my-5;
    }
}

.ant-carousel,
.slick-slider,
.slick-list {
    height: 100%;
}

.ant_slider_er {
    height: 90%;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.4);
    /* max-width: 92%; */
    overflow: hidden;
    border-radius: 20px;
    padding: 20px;
}

.ant-carousel .slick-list {
    padding: 20px;
}

.slick-slide,
.slick-track {
    height: 100%;
}

.slick-slide > div {
    height: 100%;
    width: 50%;
}
.ant-carousel .slick-dots li.slick-active button {
    background: #364d79;
}
.ant-carousel .slick-dots li button {
    background: #364d79;
}

.ant-modal-content {
    border-radius: 15px !important;
}

.ant-modal-header {
    border-radius: 15px 15px 0px !important;
}

.ant-modal-title {
    font-size: 20px !important;
    font-weight: 700 !important;
    color: #3a0ca3 !important;
}

.gd-attribute-filter .gd-button-primary {
    background: #3a0ca3;
    border-radius: 10px !important;
    height: 32px;
    color: #fff;
    /* width:120%;
    max-width: 120%; */
}

.filter > div > .gd-attribute-filter,
.scope > div > .gd-attribute-filter {
    width: 76%;
}
.filter > div > .gd-attribute-filter .gd-button-primary,
.scope > div > .gd-attribute-filter .gd-button-primary {
    width: 100%;
}

.row-span-2 {
    grid-row-end: span 2;
}
.widget-min-height {
    max-height: 300px;
}
.widget-max-height {
    max-height: 800px;
}

/* For Webkit browsers */
::-webkit-scrollbar {
    width: 9px; /* You can adjust the width as needed */
    height: 10px; /* You can adjust the height as needed */
}

::-webkit-scrollbar-track {
    background: #f1f1f1; /* You can adjust the track color */
}

::-webkit-scrollbar-thumb {
    background: #888; /* You can adjust the thumb color */
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555; /* You can adjust the hover color */
}
