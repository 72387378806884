@import "../../theme.scss";

.Label {
    display: block;
    color: $color-text-muted;
    font-size: inherit;
    text-transform: uppercase;
    font-weight: 500;
}

.Error {
    color: desaturate($color-error, 25%);
}
